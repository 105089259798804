import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCUser } from "../Cusercontext";
import BackgroundImage from "../images/bg.jpg"


export default function Home() {
  const [data, setData] = useState(null);
  const { toggleDarkMode, isDarkMode } = useCUser();

  console.log(isDarkMode);

  return (
    <div className="container mx-auto p-4">
      <main className="flex flex-col flex-1 justify-center">
        {/* Blank Space on top to prevent collision with header */}
        <div className="lg:mt-0 mt-8 xl:mt-8 w-full rounded-md sm:h-4 lg:h-20 xl:h-12"></div>
        <div className="flex flex-row gap-8">
        {/* Initial Introductory Banner */}
        <div style={{ width: "40vw" }} className="text-left mb-16 mt-2 md:pb-16">
          <h1 className="text-5xl  font-medium leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
            Transform Your Business with Core Capitas Business Solutions
            </span>
          </h1>
          <div className="text-left max-w-3xl">
            <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
            Welcome to Core Capitas, where innovation and efficiency converge. We provide tailored business solutions that streamline various processes from HR to finance and procurement; enabling you to operate efficiently, reduce costs, and focus on growth. Simplify your workflow with Core Capitas.
            </p>
          </div>
          
        </div>
        <img
          style={{ width: '40vw', height: '60vh', filter: 'grayscale(60%)' }}
          src={BackgroundImage}
          alt="Chat Background"
          className="rounded-md ml-10"
        />
      
        </div>
      </main>
    </div>
  );
}
