import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCUser } from "../Cusercontext";
import img from "../images/coming-soon.png";


export default function Home() {
  const [data, setData] = useState(null);
  const { toggleDarkMode, isDarkMode } = useCUser();

  console.log(isDarkMode);

  return (
    <div className="container mx-auto p-4">
         <div className="container ml-auto mr-auto">
      <div className="block lg:hidden ml-4 mx-3 p-2 rounded-md dark:bg-gray-800 dark:text-white">
        <div className="flex flex-col gap-2 ml-2 mt-8 ">
          <div className="md:w-9/12 flex flex-col">
            <h1 className="text-4xl md:text-7xl font-medium leading-tighter tracking-tighter mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                This Feature is Coming Soon
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-4">
                We’re working hard to bring you a brand new experience. Stay
                tuned for updates as we get closer to launch.
              </p>
              <p className="text-xl font-medium text-gray-600 dark:text-gray-400 mb-8">
                We cant wait to share what we’ve been working on!
              </p>
            </div>
            <img
              src={img}
              alt="Chat Background"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>

      <div className="hidden lg:block pt-14 pl-2 pr-2 ml-2 mr-2 rounded-md mb-8 ">
        <div className="flex flex-row gap-8">
          <div className="md:w-9/12 flex flex-col">
            <h1 className="text-5xl font-bold ml-8 mt-4 leading-tighter tracking-tighter mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                This Feature is Coming Soon
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-3xl font-light ml-8 text-gray-600 dark:text-gray-400 mb-4">
                We’re working hard to bring you a brand new experience. Stay
                tuned for updates as we get closer to launch.
              </p>
              <p className="text-2xl font-medium ml-8 text-gray-600 dark:text-gray-400 mb-8">
                We cant wait to share what we’ve been working on!
              </p>
            </div>
          </div>
          <div className="md:w-5/12 md:mt-1/2 md:ml-12 mt-8">
            <img
              src={img}
              alt="Chat Background"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        <div className="flex flex-rowgap-2"></div>
      </div>
    </div>
    </div>
  );
}
