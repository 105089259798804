import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import "./App.css";

import { CUserProvider } from "./Cusercontext";

import Layout from "./Layout";
import Home from "./pages/home";
import Work from "./pages/work";
import Thoughts from "./pages/thoughts";
import Skills from "./pages/skills";
import Contact from "./pages/contact";

function App() {
  return (
    <CUserProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Layout><Home /></Layout>}/>
            <Route path="/products" element={<Layout><Work /></Layout>}/>
            <Route path="/thoughts" element={<Layout><Thoughts /></Layout>}/>
            <Route path="/skills" element={<Layout><Skills /></Layout>}/>
            <Route path="/contact" element={<Layout><Contact/></Layout>}/>
          </Routes>
        </div>
      </Router>
    </CUserProvider>
  );
}

export default App;
