import React, { useState } from "react";
import { useCUser } from "../Cusercontext";


export default function Home() {
  const { toggleDarkMode, isDarkMode } = useCUser();
  const [activeModal, setActiveModal] = useState(null);

  const buttonLabels = ["Core HCM", "Core CRM", "Core Projects", "Core Procure", "Core Supply Chain", "Core Analytics"];

  const projectDetails = [
    {
      label: "",
      brief: "",
      role: "",
      company: "",
      resp: [
        
      ],
      stack: ["", "", "", ""],
      duration: "",
      supervisor: "",
    },
  ];

  const handleButtonClick = (index) => {
    setActiveModal(index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div className="container mx-auto p-4">
      <main className="flex flex-col flex-1 justify-center">
        {/* Blank Space on top to prevent collision with header */}
        <div className="lg:mt-0 mt-4 xl:mt-2 w-full rounded-md sm:h-4 lg:h-24 xl:h-12"></div>

        <div className="flex flex-row gap-24 align-center justify-center">
          {/* Initial Introductory Banner */}
          <div
            style={{ width: "40vw" }}
            className="text-left mb-16 mt-8 md:pb-16"
          >
            <h1 className="text-5xl font-medium leading-tighter tracking-tighter mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                Our Products
              </span>
            </h1>
            <div className="text-left max-w-3xl">
              <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
              At Core Capitas, we offer a diverse range of ERP solutions designed to streamline and enhance every aspect of your business operations. Our products are tailored to meet the unique challenges of various business functions, ensuring that your organization runs smoothly, efficiently, and with full transparency. Click on any product to explore the details.


              </p>
            </div>
          </div>

          <div className="flex flex-col w-96 gap-4">
            {buttonLabels.map((label, index) => (
              <button
                key={index}
                className="px-4 py-3 rounded-lg border border-transparent text-black dark:text-gray-300 dark:bg-stone-500 bg-gray-200 dark:bg-blue-700 hover:bg-gray-300 dark:hover:bg-neutral-500 focus:outline-none  dark:focus:ring-blue-300"
               
              >
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Modal Rendering */}
        {activeModal !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div
              style={{ width: "90vw", maxHeight: "90vh", height: "90vh" }}
              className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg overflow-y-auto"
            >
              <div className="flex flex-row gap-4">
              <h2 className="text-xl font-semibold dark:text-gray-300">
                {projectDetails[activeModal].label}
              </h2>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ml-auto mr-0 hover:cursor-pointer dark:text-white"  onClick={closeModal}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>

              </div>
              <p className="mt-2 dark:text-gray-400">
                <strong>Brief:</strong> {projectDetails[activeModal].brief}
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Role:</strong> {projectDetails[activeModal].role}
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Company:</strong> {projectDetails[activeModal].company}
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Responsibilities:</strong>
                <div className="mt-2 flex flex-col gap-2">
                  {projectDetails[activeModal].resp.map((tech, i) => (
                    <span
                      key={i}
                      className="bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300 px-3 py-2 rounded-md text-sm"
                    >
                      {i + 1}. {tech}
                    </span>
                  ))}
                </div>
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Stack:</strong>
                <div className="mt-2 flex flex-wrap gap-2">
                  {projectDetails[activeModal].stack.map((tech, i) => (
                    <span
                      key={i}
                      className="bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300 px-3 py-2 rounded-md text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Duration:</strong>{" "}
                {projectDetails[activeModal].duration}
              </p>
              <p className="mt-2 dark:text-gray-400">
                <strong>Supervisor:</strong>{" "}
                {projectDetails[activeModal].supervisor}
              </p>
              
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
