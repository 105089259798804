import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCUser } from "./Cusercontext";
import WhiteLogo from "./images/tablogo.png"
import DarkLogo from "./images/darklogo.png"

function Header() {
  const [top, setTop] = useState(true);
  const { firstname, usertype, menu, setUser, setMenu, setFirst } = useCUser();
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useCUser();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleMenuSelection = (selectedMenu) => {
    setMenu(selectedMenu);
    navigate("/" + selectedMenu);
    setIsHamburgerMenuOpen(false); // Close the menu after selection
  };

  const handleHamburgerToggle = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

    // Determine the logo based on the dark mode state
    const logoSrc = isDarkMode ? DarkLogo : WhiteLogo;

  return (
    <header
    style={{ zIndex: '2', }}

      className={`fixed w-full bg-gray-100 max-w-8xl mx-auto px-4 sm:px-6 dark:bg-neutral-900 z-0 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-gray-100 backdrop-blur-sm shadow-lg"
      }`}
    >
      <div style={{width:'93vw'}} className="flex items-center justify-between sm:h-24 h-12 md:h-12">
        {/* Site branding */}
        <div className="flex-shrink-0  mr-3 md:ml-0 lg:ml-5 xl:ml-8 text-light text-lg dark:text-white">
          <Link className="flex flex-row justify-centre items-center"  to="/">
            <img src={logoSrc} className="w-8 h-8 rounded-md" alt="Logo" />

            <span className="ml-2">CoreCapitas</span>
           
          </Link>
        </div>

        {/* Site navigation */}
        <nav className="hidden lg:flex flex-grow">
          <ul className="flex flex-grow justify-end items-center">
            <Link to="/">
              <li className="flex flex-row gap-2 items-center justify-center pt-2 pb-2 pl-4 pr-4 rounded-md hover:text-black text-light text-black dark:text-gray-400 dark:hover:text-white text-sm ml-16">
                Home
              </li>
            </Link>


           

            


         


           
            <Link to="/products">
              <li className="flex flex-row gap-2 items-center justify-center pt-2 pb-2 pl-4 pr-4 rounded-md hover:text-black text-light text-black dark:text-gray-400 dark:hover:text-white text-sm ml-4">
                Our Products
              </li>
            </Link>
           

            <li className="ml-4">
              <button
                onClick={toggleDarkMode}
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 dark:bg-neutral-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-neutral-700 rounded-full shadow transition duration-150 ease-in-out p-1.5 hover:cursor-pointer"
                aria-label="Toggle Dark Mode"
              >
                {isDarkMode ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 fill-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 fill-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                    />
                  </svg>
                )}
              </button>
            </li>
          </ul>
        </nav>

        {/* Hamburger Menu for Small Screens */}
        <div className="flex lg:hidden ml-auto mr-4 flex flex-row gap-4">
          <button
            onClick={handleHamburgerToggle}
            className="focus:outline-none text-black dark:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          <div className="">
            <button
              onClick={toggleDarkMode}
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700 rounded-full shadow transition duration-150 ease-in-out p-1.5 hover:cursor-pointer"
              aria-label="Toggle Dark Mode"
            >
              {isDarkMode ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 fill-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 fill-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Dropdown Menu for Small Screens */}
        {isHamburgerMenuOpen && (
          <div className="absolute top-12 left-0 w-full bg-gray-100 dark:bg-gray-700 shadow-md lg:hidden">
            <ul className="flex flex-col items-center">
              <li className="w-full">
                <button
                  onClick={() => handleMenuSelection("sign-in")}
                  className="w-full text-left px-4 py-2 text-sm font-semibold text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Sign In
                </button>
              </li>
              <li className="w-full">
                <button
                  onClick={() => handleMenuSelection("apply-admin")}
                  className="w-full text-left px-4 py-2 text-sm font-semibold text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Admin Program
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
    
  );
}

export default Header;
