import React, { useState } from "react";
import { useCUser } from "../Cusercontext";


export default function Home() {
  const { toggleDarkMode, isDarkMode } = useCUser();
  const [activeModal, setActiveModal] = useState(null);

  const buttonLabels = ["Core HCM", "Core CRM", "Core Projects", "Core Procure", "Core Supply Chain", "Core Analytics"];

  const projectDetails = [
    {
      label: "",
      brief: "",
      role: "",
      company: "",
      resp: [
        
      ],
      stack: ["", "", "", ""],
      duration: "",
      supervisor: "",
    },
  ];

  const handleButtonClick = (index) => {
    setActiveModal(index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div
    className="container p-4 "
    style={{
      marginLeft: '3.5vw',
     
    }}
  >
        {/* Space on top to prevent collision with header */}
        <div className="mt-8 w-full rounded-md h-4 md:h-6 xl:h-6"></div>
  
        {/* Unified template with responsive utilities */}
        <div style={{width:'90vw', height:'84vh'}} className={` rounded-md bg-purple-200 sm:bg-green-200 md:bg-yellow-200 lg:bg-white lg:dark:bg-neutral-900 xl:bg-teal-200`}>
          <div className=" flex flex-col lg:flex-row gap-4 content-center justify-center pt-[5vh] gap-36 ">
            <div className=" w-[82vw] h-[20vh] md:w-[85.8vw] md:h-[30vh] lg:w-[40vw] lg:h-[20vh]">
            <h2 className="bg-clip-text text-4xl text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
           Our Products
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400 mb-8 mt-4">
              At Core Capitas, we offer a diverse range of ERP solutions designed to streamline and enhance every aspect of your business operations. Our products are tailored to meet the unique challenges of various business functions, ensuring that your organization runs smoothly, efficiently, and with full transparency. Click on any product to explore the details.


              </p>
         
            </div>
            <div className="flex flex-row rounded-md p-2 w-[82vw] h-[20vh] md:w-[85.8vw] md:h-[30vh] lg:w-[30vw] lg:h-[20vh]">
            <div className="flex flex-col w-96 gap-4">
            {buttonLabels.map((label, index) => (
              <button
                key={index}
                className="px-4 py-3 rounded-lg border border-transparent text-black dark:text-gray-300 dark:bg-stone-500 bg-gray-200 dark:bg-blue-700 hover:bg-gray-300 dark:hover:bg-neutral-500 focus:outline-none  dark:focus:ring-blue-300"
               
              >
                {label}
              </button>
            ))}
          </div>

            </div>
      
          
          </div>
        </div>
      </div>
    
  );
}
